/** @format */

import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import DeviceDetector from "device-detector-js";
import "./style.css";

function App() {
  const [input, setInput] = useState("");
  const uniqueId = uuidv4();
  const deviceDetector = new DeviceDetector();
  const userAgent =
    "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_13_6) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/69.0.3497.81 Safari/537.36";
  const device = deviceDetector.parse(userAgent);
  const [error, setError] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [data, setData] = useState<{ mobileNumber: string; status: string }>();
  const [status, setStatus] = useState(false);
  const [message, setMessage] = useState("");
  const [message2, setMessage2] = useState("");
  const [buttonState, setButtonState] = useState(false);

  useEffect(() => {
    const transactionId = window.localStorage.getItem("transactionId");
    if (!transactionId) return;
    else {
      const urlParams = new URLSearchParams(window.location.search);
      const correlationId = urlParams.get("transactionId");
      const code = urlParams.get("code");
      const error = urlParams.get("error");
      const errorMessage = urlParams.get("errorMessage");
      if (error && errorMessage) {
        setError(error);
        setErrorMessage(errorMessage);
      } else {
        setStatus(true);
        fetch(
          `https://eoj7qs2no2msfzk.m.pipedream.net/v2/auth/userinfo?code=${
            code || ""
          }&correlationId=${correlationId || transactionId}`
        )
          .then((response) => {
            window.localStorage.removeItem("transactionId");
            return response.json();
          })
          .then((d) => {
            setData(d);
            setStatus(false);
            setButtonState(true);
            setMessage("");
          })
          .catch((e) => {
            setError(e);
          });
      }
    }
  }, []);

  console.log(status);

  const handleClick = () => {
    //@ts-ignore
    window.localStorage.setItem("transactionId", uniqueId);
    window.location.assign(
      `https://api.bureau.id/v2/auth/initiate?clientId=86c864c9-0f20-400e-bd60-6db26a1886b8&correlationId=${uniqueId}&msisdn=${input}`
    );
    setButtonState(true);
    setStatus(false);
    setMessage("✅ Initiating Phone Verification");
    setTimeout(() => {
      setMessage2("✅ Creating Mobile Data Session");
    }, 1000);
  };

  return (
    <>
      <nav className="nav">
        <img
          src="https://uploads-ssl.webflow.com/61a5d188c74343756e97d881/61b9c6ee6516da8261d3e3b5_bureau_logohorizontal_bw_black.svg"
          alt="bureau"
        />
        <h1 className="card-header"> | One Tap Login</h1>
      </nav>
      <div className="container">
        <div className="card">
          <div className="card-info">
            <div className="device-info">
              <h1 className="browser">
                Browser:{" "}
                <span className="browser-text">{`${device?.client?.name} ${device?.client?.version}`}</span>
              </h1>
              <h1 className="os">
                OS:{" "}
                <span className="os-text">{`${device?.os?.name} ${device?.os?.version}`}</span>
              </h1>
            </div>
          </div>

          {status ? (
            <div className="loading-div">
              <h1 className="loading-response">
                Fetching Response, Please wait.
              </h1>
            </div>
          ) : error ? (
            <div className="error-div">
              <div className="failure-icon">❌</div>
              <h1 className="response-title">Error Code: {error}</h1>
              <h1 className="response-subtitle">
                Error Message: {errorMessage}{" "}
              </h1>
              <button
                className="button"
                onClick={() => (window.location.href = "/")}
              >
                Go Back
              </button>
            </div>
          ) : data ? (
            <div className="response-div">
              {data.status === "Success" ? (
                <div className="failure-icon">✅</div>
              ) : (
                <div className="success-icon">❌</div>
              )}

              <h1 className="response-title">Status: {data.status}</h1>
              <h1 className="response-subtitle">
                Mobile Number: {data?.mobileNumber}{" "}
              </h1>
              <button
                className="button"
                onClick={() => (window.location.href = "/")}
              >
                Go Back
              </button>
            </div>
          ) : (
            <form className="form">
              <label>Phone Number: </label>
              <input
                type="text"
                name="phone"
                id="phone"
                placeholder="XXXXXXXXXX"
                className="input"
                onChange={(e) => setInput(e.target.value)}
                required
              />
              <p className="span">
                Note: Phone number needs to be in international format (e.g. IN
                91XXXXXXXXXX)
              </p>
              <button
                className="button"
                type="button"
                id="verify-number"
                disabled={buttonState}
                onClick={handleClick}
              >
                Verify my phone number
              </button>
            </form>
          )}
          {message && (
            <div className="message-div">
              <h1 className="response-title">{message}</h1>
            </div>
          )}

          {message2 && (
            <div className="message-div">
              <h1 className="response-title">{message2}</h1>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default App;
